import React from "react";
import { useSelector } from "react-redux";
import { useActions } from "./useActions";
import { AuthService } from "src/services/AuthService";

export const usePatchTranslationStatus = () => {
	  const user = AuthService.getUser();
	
	const { translationsGetAll, setCurrentTranslations } = useActions();
	const {
		selectedId,
		currentTranslations,
		all
	} = useSelector(state => state.translationsReducer);

	return (status) => {
		console.log(status, 'status');

		// TranslationsService.patchStatus({
		// 	translationId: selectedId,
		// 	status
		// });
		translationsGetAll(all.map(item => {
			if (item.id === currentTranslations.id) {
				return {
					...item,
					status,
					interpreter: { name: user.name, id: user.id },
					interpreter_id: user.id
				}
			}

			return item;
		}));
		//const item = all.find(item => item.id === currentTranslations.id);

		setCurrentTranslations({
      // ...item,
      ...currentTranslations,
      status,
      interpreter: { name: user.name, id: user.id },
      interpreter_id: user.id,

      // records: currentTranslations.records
    });

		//console.log(item, 'check translations');
		console.log(currentTranslations, 'did current translations change?');
	}
};
