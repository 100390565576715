import { createSlice } from "@reduxjs/toolkit";
import { Operator } from "src/types";
import { fetchCurrentInterpreter } from "./operations";

interface InterpreterState {
  currentInterpreter: Operator | null;
  allInterpreters: Operator[];
}

const initialState: InterpreterState = {
  currentInterpreter: null,
  allInterpreters: [],
};

const interpreterSlice = createSlice({
  name: "interpreterSlice",
  initialState,
  reducers: {
    setCurrentInterpreter(state, action) {
      state.currentInterpreter = action.payload;
    },
    setAllInterpreters(state, action) {
      state.allInterpreters = action.payload;
    },
  },
  extraReducers(builder) {
    return builder.addCase(fetchCurrentInterpreter.fulfilled, (state, action) => {
      state.currentInterpreter = action.payload.currentInterpreter;
      state.allInterpreters = action.payload.allInterpreters;
    });
  },
});

export const { setCurrentInterpreter, setAllInterpreters } = interpreterSlice.actions;
export default interpreterSlice.reducer;
