// import { translationsListType } from './TranslationsSlice';
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AuthorType, MessagesType, TranslationStatus } from "../../types";

export type MessageType = {
  author_type: AuthorType;
  content: string;
  created_at: number;
  id: number;
  payed: null | any;
  thumbnail: string;
  type: MessagesType;
};

export type translationType = {
  id: number;
  user_id: number;
  interpreter_id: number | null;
  files: string[];
  status: TranslationStatus;
  created_at: Date;
  updated_at: Date;
  user?: User | null;
  records?: any;
  messages: MessageType[] | [];
};

export type Member = {
  id: number;
  phone: string;
  userId: number;
  firstName: string;
  lastName: string;
  patronymic: string;
  identification: any;
  placeOfRegistration: any;
  validated: boolean;
  msekFront: any;
  msekBack: any;
  msekNumber: any;
  msekSerial: any;
};

export type User = {
  id: number;
  activity: boolean;
  region: any;
  blockedTimes: number;
  blockedTill: any;
  deletedAt: any;
  createdAt: string;
  updatedAt: string | null;
  login: string;
  passwodr: any;
  languages: any;
  profileId: number | null;
  member?: Member | null;
};

// export type translationsListType = {
// 	from: number;
// 	to: number;
// 	per_page: number;
// 	total: number;
// 	current_page: number;
// 	prev_page: number | null;
// 	next_page: number | null;
// 	last_page: number | null;
// 	data: translationType[];
// };

export type translationsInitialState = {
  all: translationType[];
  users: User[] | null;
  selectedId: number;
  selectedFileId: number | null;
  isPressed: boolean;
  signedForTranslation: boolean;
  refusedToTranslate: boolean;
  currentTranslations: translationType | null;
  completed: boolean;
  detailedImage: string | null;
};

const initialState: translationsInitialState = {
  all: [],
  users: null,
  selectedId: 0,
  selectedFileId: null,
  isPressed: false,
  signedForTranslation: false,
  refusedToTranslate: false,
  currentTranslations: null,
  completed: false,
  detailedImage: null,
};

export const translationsSlice = createSlice({
  name: "translations",
  initialState,
  reducers: {
    translationsGetAll: (state, action: PayloadAction<translationType[]>) => {
      state.all = action.payload;
    },
    translationsGetUsers: (state, action: PayloadAction<User[]>) => {
      state.users = action.payload;
    },
    selectTranslation: (state, action: PayloadAction<number>) => {
      state.selectedId = action.payload;
    },
    setInitialMessages: (state, action) => {
      if (state.currentTranslations && state.currentTranslations.messages) {
        state.currentTranslations.messages = [...action.payload];
      }
    },
    addMessages: (state, action) => {
      if (state.currentTranslations && state.currentTranslations.messages) {
        state.currentTranslations.messages = [
          ...state.currentTranslations.messages,
          action.payload,
        ];
      }
    },
    selectFile: (state, action: PayloadAction<number>) => {
      state.selectedFileId = action.payload;
    },
    setIsPressed: (state, action: PayloadAction<boolean>) => {
      state.isPressed = action.payload;
    },
    signForTranslation: (state, action: PayloadAction<boolean>) => {
      state.signedForTranslation = action.payload;
    },
    refuseToTranslate: (state, action: PayloadAction<boolean>) => {
      state.refusedToTranslate = action.payload;
    },
    setCurrentTranslations: (state, action: PayloadAction<translationType>) => {
      state.currentTranslations = action.payload;
    },
    clearCurrentTranslations: (state) => {
      state.currentTranslations = null;
    },
    setCompleted: (state, action: PayloadAction<boolean>) => {
      state.completed = action.payload;
    },
    removeTranslation: (state, action: PayloadAction<number>) => {
      state.all = state.all.filter((el) => el.id !== action.payload);
    },
    setDetailedImage: (state, action: PayloadAction<string>) => {
      state.detailedImage = action.payload;
    },
    clearDetailedImage: (state) => {
      state.detailedImage = null;
    }
  },
});

export const {
  translationsGetAll,
  translationsGetUsers,
  selectTranslation,
  selectFile,
  setIsPressed,
  signForTranslation,
  refuseToTranslate,
  setCurrentTranslations,
  setCompleted,
  addMessages,
  setInitialMessages,
  removeTranslation,
  clearCurrentTranslations,
  setDetailedImage,
  clearDetailedImage,
} = translationsSlice.actions;

export default translationsSlice.reducer;
