import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Box, ButtonBase, Typography, makeStyles } from "@material-ui/core";
import { motion } from "framer-motion";

// import VideoCallIcon from "../../assets/videoCallIcon.svg";
// import FireIcon from "../../assets/fireIcon.svg";
// import EmergencyIcon from "../../assets/ambulanceIcon.svg";
// import GasIcon from "../../assets/gasIcon.svg";
// import PoliceIcon from "../../assets/policeIcon.svg";
// import CallRequestIcon from "../../assets/callOutIcon.svg";
import Alarm from "../../assets/alarm.svg";
import toast from "react-hot-toast";
// import { PhoneForwardedOutlined } from "@mui/icons-material";
import PhoneForwardedOutlined from "../../assets/callback-icon.svg";

// import CallLottie from "../../assets/lottie/call-normal.json";
// import RedLottie from "../../assets/lottie/call-101-103.json";
// import BlueLottie from "../../assets/lottie/call-102.json";
// import YellowLottie from "../../assets/lottie/call-104.json";

// import RuIcon from '../../assets/ru-icon.svg'
// import UaIcon from '../../assets/ua-icon.svg'
// import EnIcon from '../../assets/en-icon.svg'
// import ArIcon from '../../assets/ar-icon.svg'
// import PkIcon from '../../assets/pk-icon.svg'
// import InIcon from '../../assets/in-icon.svg'
// import IslIcon from '../../assets/isl-icon.svg'
// import CallIcon from "../../assets/call-icon.svg";
// import PartnerCallIcon from "../../assets/widget.svg";
// import TabletIcon from "../../assets/tablet.svg";
// import QRCodeIcon from "src/assets/qrCodeIcon.svg";

import PoliceIconSmall from "../../assets/police-icon.svg";
// import HeartIconSmall from "../../assets/heart-icon.svg";
import GasIconSmall from "../../assets/gas-icon.svg";
// import LawIconSmall from "../../assets/lawyer-icon.svg";
import DoctorIconSmall from "../../assets/doctor-icon.svg";
import FireIconSmall from "../../assets/fire-icon.svg";

import { CallActionTypes } from "../../reducers/Call/CallActionsTypes";
import { useCallContext } from "../../reducers/Call/CallProvider";
// import { Timer } from "../../utils/Timer";
import { useTranslation } from "react-i18next";
// import { reasonTypes } from "./types";
import { CallReason, CallStatus, Operator } from "../../types";
import { formatTimeFromSeconds } from "../../utils/CustomDate";
import { CallService } from "../../services/CallService";
import { CallDataType, CallTypes } from "src/types/CallType";
import { useIsOpen, useUnixTimeFormat } from "src/hooks";
import { Icon, IconName } from "src/components/Icon";
import { checkPaidMinutes } from "./utils";
import TimeCounter from "src/components/TimeCounter";
import { useDispatch, useSelector } from "react-redux";
import { setSessionIdToSubmit, setUserReasonSelected } from "src/reducers/Reasons";
import { selectAllInterpreters } from "src/reducers/Interpreter/selectors";

// type languageType = 'ru' | 'en' | 'ua' | 'pk' | 'ar' | 'in' | 'isl'

const useStyles = makeStyles(() => ({
  animatedItem: {
    animation: `$call 500ms steps(1) infinite`,
  },
  animatedItemPaid: {
    animation: `$callPaid 500ms steps(1) infinite`,
  },
  "@keyframes call": {
    "0%": {
      backgroundColor: "#43A047",
    },
    "50%": {
      backgroundColor: "#fff",
    },
    "100%": {
      backgroundColor: "#43A047",
    },
  },
  "@keyframes callPaid": {
    "0%": {
      backgroundColor: "#FFE87C",
    },
    "50%": {
      backgroundColor: "#43A047",
    },
    "100%": {
      backgroundColor: "#FFE87C",
    },
  },
  textHovered: {
    padding: 2,
    borderRadius: 4,
    "&:hover": {
      backgroundColor: "#e7e4e4",
    },
  },
  toast: {
    background: "#43A047",
    width: "342px",
    display: "flex",
    flexDirection: "column",
    height: "max-content",
    borderRadius: "4px",
    padding: "5px 25px",
    color: "#fff",
    boxShadow: "none",
  },
}));

interface CallInfoProps {
  callData: CallDataType;
}

export const RenderCalls = () => {
  const { callState } = useCallContext() as any;
  const { t } = useTranslation();

  const { othersCalls } = callState;

  // const calls = isRenderSelected
  //   ? [selectedCall, ...incomingCalls, ...othersCalls]
  //   : [...incomingCalls, ...othersCalls];

  function getCallsForToday(calls: CallDataType[]) {
    const today = new Date();
    const startOfDay = new Date(today.getFullYear(), today.getMonth(), today.getDate(), 0, 0, 0);
    const endOfDay = new Date(today.getFullYear(), today.getMonth(), today.getDate(), 23, 59, 59);

    return calls.filter((call) => {
      const callTime = new Date(call.started_at * 1000);
      return callTime >= startOfDay && callTime <= endOfDay;
    });
  }

  const calls: CallDataType[] = getCallsForToday([...othersCalls]);

  return calls.length > 0 ? (
    <>
      <Typography
        style={{
          backgroundColor: "rgb(33, 150, 243)",
          color: "#fff",
          textAlign: "center",
          position: "sticky",
          top: 0,
          zIndex: 300,
        }}
        variant="h6"
      >
        {t("routes./calls")}
      </Typography>
      {calls.map((call) => {
        return <CallInfo key={call.session_id + call.id} callData={call} />;
      })}
    </>
  ) : (
    <Box
      style={{
        display: "flex",
        justifyItems: "center",
        alignItems: "center",
        flexDirection: "column",
        justifyContent: "center",
        height: "100%",
      }}
    >
      <Typography variant="subtitle1">{t("callsHere")}</Typography>
    </Box>
  );
};

interface callTime {
  minutes: string;
  seconds: string;
}

export function CallInfo({ callData }: CallInfoProps) {
  const [isDisabled, setIsDisabled] = useState(false);
  const [startTime, setStartTime] = useState<Date | null>(null);
  const [callDuration, setCallDuration] = useState<callTime | null>(null);
  const [waitingTime, setWaitingTime] = useState<callTime | null>(null);
  const { callState, dispatchCall, handleAccept, endCall, openSetReasonModal } =
    useCallContext() as any;
  const { t } = useTranslation();
  const classes = useStyles();
  const interpreters = useSelector(selectAllInterpreters);

  // const isGroupCall = useMemo(() => callData.reason === "group", [callData.reason]);
  // const data = useMemo(() => getMetaData(callData.reason), [callData.reason]);
  const { icon, text } = useMemo(() => getCallReason(callData.reason), [callData.reason]);
  const { isOpen, toggle, close } = useIsOpen();
  const dispatch = useDispatch();

  const {
    time: { hours: localHours, minutes: localMinutes },
  } = useUnixTimeFormat(callData.started_at);

  const getStatusClassName = useCallback(
    (sessionData: CallDataType) => {
      if (sessionData.status !== CallStatus.NEW) return "";
      return checkPaidMinutes(sessionData) ? classes.animatedItemPaid : classes.animatedItem;
    },
    [classes.animatedItem, classes.animatedItemPaid]
  );

  const handleSetCall = useCallback(
    async (el: CallDataType) => {
      if (callState.selectedCall.token) {
        endCall();
      }
      setIsDisabled(true);
      setTimeout(() => {
        return setIsDisabled(false);
      }, 3000);

      let data: any;
      try {
        data =
          el.type === "CONNECT_PRO_WEB_GROUP_CALL"
            ? await CallService.answerGroupWebProCall(el.session_id)
            : await CallService.answerCall(el.session_id);
      } catch (error) {
        console.error("Error answering call:", error);
        endCall();
        return;
      }

      if (!data.success) {
        toast(data.message);
        endCall();
        return;
      }
      const dataForDispatch =
        el.type === "CONNECT_PRO_WEB_GROUP_CALL"
          ? {
              ...el,
              ...data,
              api_key: data.call.api_key,
              token: data.call.token,
              session_id: data.call.session_id,
            }
          : { ...el, ...data };
      dispatchCall({
        type: CallActionTypes.PREVIEW_CALL,
        payload: dataForDispatch,
      });

      handleAccept();
      dispatch(setUserReasonSelected(el.reason));
      dispatch(setSessionIdToSubmit(el.session_id));
    },
    [dispatchCall, handleAccept, dispatch, endCall, callState.selectedCall.token]
  );

  useEffect(() => {
    if (callData.answered_at) {
      setStartTime(new Date(callData.answered_at * 1000));
    }
    // if (callData.status === CallStatus.MISSED) {
    setStartTime(new Date(callData.started_at * 1000));
    // }
  }, [callData]);

  useEffect(() => {
    if (startTime && callData.ended_at && callData.answered_at) {
      const { seconds, minutes } = formatTimeFromSeconds(callData.ended_at - callData.answered_at);

      setCallDuration({
        minutes,
        seconds,
      });
    }
    if (startTime && callData.status === CallStatus.MISSED) {
      const { seconds, minutes } = formatTimeFromSeconds(callData.ended_at - callData.started_at);

      setWaitingTime({
        minutes,
        seconds,
      });
    }
  }, [startTime, callData.answered_at, callData.started_at, callData.ended_at, callData.status]);

  const handleBlockUser = async () => {
    toast(t("blockUser.userBlocked", { userName: callData.name }), {
      className: classes.toast,
      duration: 5000,
    });
    const response = await CallService.blockUser(callData.session_id);
    console.log("response", response); // return {succes:true}
  };
  const handleChangeReason = () => {
    dispatch(setUserReasonSelected(callData.reason));
    dispatch(setSessionIdToSubmit(callData.session_id));
    close();
    openSetReasonModal();
  };
  const CallStatusColorMap: Record<string, string> = {
    [CallStatus.MISSED]: "#D0021B",
    [CallStatus.ANSWERED]: "#4CAF50",
    [CallStatus.ENDED]: "#2196F3",
  };

  const handleCallback = (client_id: number) => {
    CallService.makeCall(client_id)
      .then((data: any) => {
        if (!data.success) {
          toast.error(data.message);
          return;
        }
        endCall();

        dispatchCall({
          type: CallActionTypes.PREVIEW_CALL,
          payload: data,
        });
        handleAccept();
      })
      .catch(console.log);
  };

  const renderInterpreterName = React.useCallback(
    (callData: CallDataType) => {
      if (!interpreters || interpreters.length === 0) {
        return null;
      }

      return (
        interpreters.find(
          (interpreter: Operator) =>
            String(interpreter.id).trim().toLowerCase() ===
            String(callData.interpreter_id).trim().toLowerCase()
        ).name || ""
      );
    },
    [interpreters]
  );

  const renderName = React.useCallback((callData: CallDataType) => {
    // console.log("select", select);
    let nameParts = callData.name ? callData.name.split(" - ") : [];

    const clientName = callData.client_name || nameParts[0] || "";
    const displayReason = callData.display_reason || nameParts[1] || "";
    const clientPhone = callData.client_phone || nameParts[2] || "";

    const mergedParts = [clientName, displayReason, clientPhone].filter(Boolean);
    const mergedName = mergedParts.join(" - ");

    return mergedName.length > callData.name.length ? mergedName : callData.name;
  }, []);

  const slideInVariants = {
    hidden: { x: "-100%", opacity: 0 },
    visible: { x: 0, opacity: 1, transition: { duration: 0.2 } },
    exit: { x: "100%", opacity: 0, transition: { duration: 0.2 } },
  };

  return (
    callData && (
      <motion.div
        initial="hidden"
        animate="visible"
        exit="hidden"
        variants={slideInVariants}
        transition={{ duration: 0.2, ease: "easeOut" }}
      >
        <ButtonBase
          className={getStatusClassName(callData)}
          style={{
            width: "100%",
            flexDirection: "column",
            gap: 8,
            padding: 6,
            borderBottom: "1px solid #ccc",
            backgroundColor: checkPaidMinutes(callData) ? "#FFC700" : "",
            // backgroundColor: getStatusBackgroundColor({ call: callData, isGroupCall }),
          }}
          disabled={isDisabled}
          // disabled={
          //   isDisabled ||
          //   callData.status === CallStatus.ANSWERED ||
          //   callData.status === CallStatus.LOCKED ||
          //   callData.status === CallStatus.MISSED ||
          //   callData.reason === "callback"
          // }
          onClick={() => {
            if (callData.status !== CallStatus.NEW) {
              return;
            }

            handleSetCall(callData);
          }}
        >
          <Box style={{ gap: 8 }} sx={{ display: "flex", width: "100%" }}>
            <Box
              style={{
                width: 70,
                // marginRight: 10,
                height: "auto",
                display: "flex",
                flexDirection: "column",
                position: "relative",
                alignItems: "center",
                // justifyContent: "center",
                justifyContent: "space-between",
              }}
            >
              <CallIconByType
                // size="60px"
                iconWidth="62px"
                iconHeight="55px"
                // isHadPaidMinutes={checkPaidMinutes(callData)}
                callType={callData.type}
                callStatus={callData.status}
              />
              <ReasonChip icon={icon} text={text} />
            </Box>

            <Box
              style={{
                padding: 0,
                width: "100%",
                display: "flex",
                flexDirection: "column",
                gap: 4,
                justifyContent: "space-between",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  // alignItems: "center",
                  alignItems: "flex-start",
                  // marginBottom: 8,
                  //@ts-ignore
                  gap: 4,
                }}
              >
                <Typography style={{ userSelect: "text" }} variant="body1">
                  {renderName(callData)}
                </Typography>
                {/* todo: Warning: validateDOMNesting(...): <button> cannot appear as a descendant of <button>. Error Component Stack */}
                {callData.status === CallStatus.MISSED && callData.client_id && (
                  <ButtonBase
                    style={{
                      gap: 4,
                      border: "1px solid #2196F3",
                      borderRadius: "50%",
                      padding: 4,
                    }}
                    onClick={(event) => {
                      event.stopPropagation();

                      handleCallback(callData.client_id);
                    }}
                  >
                    <img
                      style={{
                        width: 36,
                        height: 36,
                      }}
                      src={PhoneForwardedOutlined}
                      alt=""
                    />
                    {/* <PhoneForwardedOutlined style={{ width: 36, height: 36, color: "#2196F3" }} /> */}
                  </ButtonBase>
                )}
                {/* dropdown */}
                <Box
                  sx={{
                    fontSize: 24,
                    bgcolor: "lightgray",
                    borderRadius: 4,
                    position: "relative",
                  }}
                >
                  <Typography
                    onClick={(e) => {
                      e.stopPropagation();
                      toggle();
                    }}
                    style={{
                      padding: 8,
                      cursor: "pointer",
                    }}
                  >
                    &#8942;
                  </Typography>
                  {isOpen ? (
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        gridGap: 8,
                        width: "200px",
                        position: "absolute",
                        zIndex: 200,
                        padding: 4,
                        right: 0,
                        bgcolor: "white",
                        borderRadius: 4,
                        border: "1px solid gray",
                      }}
                    >
                      <Typography
                        style={{ borderBottom: "1px solid gray", width: "100%" }}
                        variant="h6"
                      >
                        {t("blockUser.choose")}
                      </Typography>

                      <Typography
                        onClick={handleBlockUser}
                        className={classes.textHovered}
                        variant="body1"
                      >
                        {t("blockUser.block")}
                      </Typography>
                      <Typography
                        onClick={handleChangeReason}
                        className={classes.textHovered}
                        variant="body1"
                      >
                        {t("changeReason")}
                      </Typography>
                    </Box>
                  ) : null}
                </Box>
              </Box>
              <Box sx={{ display: "flex", justifyContent: "space-between", height: 24 ,alignItems: "center"}}>
                {callData.status === CallStatus.ANSWERED && (
                  <Typography
                    variant="caption"
                    style={{ maxWidth: "146px", overflow: "hidden", textOverflow: "ellipsis" }}
                  >
                    {renderInterpreterName(callData)}
                  </Typography>
                )}

                <Typography variant="body1">{`${localHours}:${localMinutes}`}</Typography>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gridGap: 2,
                  }}
                >
                  {/* {callData.status === CallStatus.ANSWERED || callData.status === CallStatus.ENDED ? (
                <img style={{ width: "18px" }} src={Alarm} alt="alarm" />
              ) : null} */}
                  <img style={{ width: "18px" }} src={Alarm} alt="alarm" />
                  <Typography
                    variant="body2"
                    style={{
                      color: CallStatusColorMap[callData.status],
                    }}
                  >
                    {callData.status === CallStatus.ANSWERED ? (
                      <TimeSinceAnswered answeredAt={callData.answered_at} />
                    ) : null}
                    {callData.status === CallStatus.ENDED && callDuration
                      ? `${String(callDuration.minutes).padStart(2, "0")}:${String(
                          callDuration.seconds
                        ).padStart(2, "0")}`
                      : null}
                    {callData.status === CallStatus.MISSED && waitingTime
                      ? `${String(waitingTime.minutes).padStart(2, "0")}:${String(
                          waitingTime.seconds
                        ).padStart(2, "0")}`
                      : null}
                    {callData.status === CallStatus.NEW ? (
                      <TimeCounter startedAt={callData.started_at} />
                    ) : null}
                  </Typography>
                </Box>
              </Box>

              {Boolean(callData.deaf_id) && (
                <Box
                  sx={{
                    position: "relative",
                    zIndex: 100,
                    display: "flex",
                    alignSelf: "flex-start",
                    gridGap: 4,
                    flexWrap: "wrap",
                    // marginLeft: "90px",
                  }}
                >
                  <Icon color="#2196F3" name={IconName.DEAF_ID} />
                  <Typography style={{ userSelect: "text" }}>{callData.deaf_id}</Typography>
                </Box>
              )}

              {callData.tags && callData.tags.length ? (
                <Box
                  style={{
                    userSelect: "text",
                  }}
                  sx={{
                    position: "relative",
                    zIndex: 100,
                    display: "flex",
                    alignSelf: "flex-start",
                    gridGap: 4,
                    flexWrap: "wrap",
                    // marginLeft: "90px",
                  }}
                >
                  {callData.tags.map((tag, i) => {
                    return (
                      <Box
                        key={tag + i}
                        sx={{ padding: 4, borderRadius: 4, bgcolor: "#ffc107", fontWeight: 600 }}
                      >
                        {tag}
                      </Box>
                    );
                  })}
                </Box>
              ) : null}
            </Box>
          </Box>
          {/* {Boolean(callData.deaf_id) && (
          <Box
            sx={{
              position: "relative",
              zIndex: 100,
              display: "flex",
              alignSelf: "flex-start",
              gridGap: 4,
              flexWrap: "wrap",
              marginLeft: "90px",
            }}
          >
            <Icon color="#2196F3" name={IconName.DEAF_ID} />
            <Typography style={{ userSelect: "text" }}>{callData.deaf_id}</Typography>
          </Box>
        )}

        {callData.tags && callData.tags.length ? (
          <Box
            style={{
              userSelect: "text",
            }}
            sx={{
              position: "relative",
              zIndex: 100,
              display: "flex",
              alignSelf: "flex-start",
              gridGap: 4,
              flexWrap: "wrap",
              marginLeft: "90px",
            }}
          >
            {callData.tags.map((tag, i) => {
              return (
                <Box
                  key={tag + i}
                  sx={{ padding: 4, borderRadius: 4, bgcolor: "#ffc107", fontWeight: 600 }}
                >
                  {tag}
                </Box>
              );
            })}
          </Box>
        ) : null} */}
        </ButtonBase>
      </motion.div>
    )
  );
}

const CallIconByType = ({
  callType,
  callStatus,
  iconHeight = "auto",
  iconWidth = "auto",
}: // isHadPaidMinutes,
{
  callType: CallTypes;
  callStatus: CallStatus;
  iconWidth?: string;
  iconHeight?: string;
  // isHadPaidMinutes: boolean;
}) => {
  const [description, setDescription] = useState("");
  const { t } = useTranslation();

  useEffect(() => {
    switch (callType) {
      case CallTypes.CLIENT_CALL:
        setDescription(t("callTypes.mobile"));
        break;
      case CallTypes.CONNECT_PRO_CALL:
        setDescription(t("callTypes.tablet"));
        break;
      case CallTypes.PARTNER_CALL:
        setDescription(t("callTypes.widget"));
        break;
      case CallTypes.WIDGET_CALL:
        setDescription(t("callTypes.qrCode"));
        break;
      default:
        setDescription(t("callTypes.qrCode"));
        break;
    }
  }, [callType, t]);

  const DEFAULT_COLOR = "#FFC700";

  const ColorsMap: Partial<Record<CallStatus, string>> = {
    [CallStatus.ANSWERED]: "#4CAF50",
    [CallStatus.MISSED]: "#D0021B",
    [CallStatus.ENDED]: "#2196F3",
  };

  return (
    <>
      <Icon
        name={IconName[callType]}
        width={iconWidth}
        height={iconHeight}
        color={ColorsMap[callStatus] || DEFAULT_COLOR}
      />
      <Typography style={{ height: 20 }} variant="caption">
        {description}
      </Typography>
    </>
  );
};

export const TimeSinceAnswered = ({ answeredAt }: any) => {
  const [timeSinceAnswered, setTimeSinceAnswered] = React.useState(0);

  React.useEffect(() => {
    const interval = setInterval(() => {
      const currentTime = Math.floor(Date.now() / 1000);
      setTimeSinceAnswered(currentTime - answeredAt);
    }, 1000);

    return () => clearInterval(interval);
  }, [answeredAt]);

  const formatTime = (seconds: any) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;

    return `${String(minutes).padStart(2, "0")}:${String(remainingSeconds).padStart(2, "0")}`;
  };

  return <>{formatTime(timeSinceAnswered)}</>;
};

const ReasonChip = ({ icon, text }: { icon: string; text: string | null }) => {
  const { t } = useTranslation();
  const shouldRender = icon || text;

  return shouldRender ? (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: "100%",
        minHeight: 20,
        border: "1px solid #008DFF",
        borderRadius: 8,
        padding: 4,
        // marginBottom: 10,
      }}
    >
      {icon ? <img src={icon} alt={text || ""} style={{ marginRight: 5, width: "auto" }} /> : null}
      {text ? (
        <p
          style={{
            fontSize: 10,
            color: "#008DFF",
            wordBreak: "keep-all",
            wordWrap: "break-word",
          }}
        >
          {t(`reasons.${text}`)}
        </p>
      ) : null}
    </div>
  ) : null;
};

// const getMetaData = (reason: reasonTypes) => {
//   switch (reason) {
//     case "emergency":
//       return {
//         lottie: RedLottie,
//         icon: EmergencyIcon,
//         text: "callTypes.103",
//       };
//     case "fire department":
//       return {
//         lottie: RedLottie,
//         icon: FireIcon,
//         text: "callTypes.101",
//       };
//     case "police":
//       return {
//         lottie: BlueLottie,
//         icon: PoliceIcon,
//         text: "callTypes.102",
//       };
//     case "gas service":
//       return {
//         lottie: YellowLottie,
//         icon: GasIcon,
//         text: "callTypes.104",
//       };
//     case "other":
//       return {
//         lottie: CallLottie,
//         icon: VideoCallIcon,
//         text: "callTypes.videoCall",
//       };
//     case "group":
//       return {
//         lottie: CallLottie,
//         icon: VideoCallIcon,
//         text: "callTypes.groupCall",
//       };
//     case "request":
//       return {
//         lottie: CallLottie,
//         icon: CallRequestIcon,
//         text: "callTypes.callRequest",
//       };
//     default:
//       return {
//         lottie: CallLottie,
//         icon: VideoCallIcon,
//         text: "callTypes.videoCall",
//       };
//   }
// };

// const getCountry = (country: languageType) => {
//     switch (country) {
//         case 'ua':
//             return UaIcon
//         case 'ru':
//             return RuIcon
//         case 'en':
//             return EnIcon
//         case 'ar':
//             return ArIcon
//         case 'in':
//             return InIcon
//         case 'pk':
//             return PkIcon
//         case 'isl':
//             return IslIcon
//         default:
//             return null
//     }
// }

const getCallReason = (reason: string) => {
  switch (reason) {
    case CallReason.EMERGENCY:
      return {
        icon: DoctorIconSmall,
        text: CallReason.EMERGENCY,
      };
    case CallReason.FIRE_DEPARTMENT:
      return {
        icon: FireIconSmall,
        text: CallReason.FIRE_DEPARTMENT,
      };
    case CallReason.POLICE:
      return {
        icon: PoliceIconSmall,
        text: CallReason.POLICE,
      };
    case CallReason.GAS_SERVICE:
      return {
        icon: GasIconSmall,
        text: CallReason.GAS_SERVICE,
      };
    case CallReason.DOCTOR:
      return {
        icon: DoctorIconSmall,
        text: CallReason.DOCTOR,
      };
    case CallReason.CALL:
      return {
        icon: null,
        text: CallReason.CALL,
      };
    case CallReason.OTHER:
      return {
        icon: null,
        text: CallReason.OTHER,
      };
    case CallReason.BPVD:
      return {
        icon: null,
        text: CallReason.BPVD,
      };
    case CallReason.ESTORE:
      return {
        icon: null,
        text: CallReason.ESTORE,
      };
    case CallReason.BANK:
      return {
        icon: null,
        text: CallReason.BANK,
      };
    case CallReason.WORK:
      return {
        icon: null,
        text: CallReason.WORK,
      };
    case CallReason.CAR_SERVICE:
      return {
        icon: null,
        text: CallReason.CAR_SERVICE,
      };
    case CallReason.HOTLINE_SE:
      return {
        icon: null,
        text: CallReason.HOTLINE_SE,
      };
    case CallReason.SOCIAL:
      return {
        icon: null,
        text: CallReason.SOCIAL,
      };
    case CallReason.DISABILITY_RIGHTS:
      return {
        icon: null,
        text: CallReason.DISABILITY_RIGHTS,
      };
    default:
      return {
        icon: null,
        text: null,
      };
  }
};
