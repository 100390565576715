import React, { Fragment, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Typography, Modal } from "@material-ui/core";
import DeclineBtn from "../../assets/declineButton.svg";
import { OTPublisher, OTSession, OTSubscriber } from "opentok-react";
import CamOnIcon from "../../assets/camOnIcon.svg";
import CamOffIcon from "../../assets/camOffIcon.svg";
import AudioOnIcon from "../../assets/sound-off-icon.svg";
import AudioOffIcon from "../../assets/volumeIconOff.svg";
import NoUserIcon from "../../assets/no-user-icon.svg";
import { useCallContext } from "src/reducers/Call/CallProvider";
import { ConstructionOutlined } from "@mui/icons-material";
import HandUpIcon from "../../assets/hand-up-icon.svg";
import HandDown from "../../assets/handDown.svg";
import { OpentokSignals } from "src/types";
// import toast, { Toaster } from "react-hot-toast";

// interface Props {
//   selectedGroupSession: GroupSession;
//   publisher: Publisher;
//   onDisconnect: () => void;
// }

export default function GroupCallView({ publisher, selectedGroupSession, onDisconnect }) {
  // const sessionRef = useRef(null);
  const [subscribersToRender, setSubscribersToRender] = useState([]);
  // const [streamAvailable, setStreamAvailable] = useState(false);
  const [handUpCount, setHandUpCount] = useState([]);
  const [isOffSpeaker, setIsOffSpeaker] = useState(false);
  const [streamAvailable, setStreamAvailable] = useState(false);
  const filteredSubscribers = useMemo(
    () => subscribersToRender.filter((sub) => sub.isSelectedUser),
    [subscribersToRender]
  );
  const isGroupCall = true;
  const [isOpen, setIsOpen] = useState(false);

  const {
    callState,
    dispatchCall,
    reserveCall,
    sessionRef,
    setChatMessages,
    setRightTabId,
    canStartCall,
    setCanStartCall,
    handleAccept,
    endCall,
    flag,
  } = useCallContext();

  const handleUserAudioToggle = useCallback(
    (subscriber) => {
      setSubscribersToRender((prevState) => {
        return prevState.map((el) =>
          el.stream.id === subscriber.stream.id ? { ...el, isAudioOn: !subscriber.isAudioOn } : el
        );
      });

      sessionRef.current.sessionHelper.session.signal({
        data: subscriber.stream.id,
        type: subscriber.isAudioOn ? OpentokSignals.BlockMicro : OpentokSignals.UnblockMicro,
      });
    },
    [sessionRef]
  );

  const handleUserVideoToggle = useCallback(
    (subscriber) => {
      console.log("handleUserVideoToggle", subscriber);
      setSubscribersToRender((prevState) => {
        return prevState.map((el) =>
          el.stream.id === subscriber.stream.id ? { ...el, isCameraOn: !subscriber.isCameraOn } : el
        );
      });

      sessionRef.current.sessionHelper.session.signal({
        data: subscriber.stream.id,
        type: subscriber.isCameraOn ? OpentokSignals.BlockCamera : OpentokSignals.UnblockCamera,
      });
    },
    [sessionRef, isGroupCall]
  );

  const hasRole = useCallback((streamConnectionData, roleToCheck) => {
    try {
      const role = JSON.parse(streamConnectionData).role;
      return role === roleToCheck;
    } catch (error) {
      console.error("Error parsing connection data or missing role data:", error);
      return false;
    }
  }, []);

  const sessionEventHandlers = {
    streamCreated: (event) => {
      console.log("Stream Created", event);
      const { stream } = event;
      const isSpeaker = hasRole(event.stream.connection.data, "speaker");

      const newUser = {
        stream,
        isCameraOn: stream.hasVideo,
        isAudioOn: stream.hasAudio,
        name: stream.name,
        isSelectedUser: isSpeaker,
        isHaveQuestion: false,
        isSpeaker
      };

      setSubscribersToRender((prevState) => [...prevState, newUser]);

      setStreamAvailable(true);
      // flag.current = true;
    },
    streamDestroyed: (event) => {
      console.log("Stream Destroyed", event);
      const userName = event.stream.name;
      const isGroupCall = true;

      setSubscribersToRender((prevState) =>
        prevState.filter((el) => el.stream.id !== event.stream.id)
      );

      // if (userName.includes("amsaanpro") || !isGroupCall)
      // dispatchCall({
      //   type: CallActionTypes.END_CALL,
      // });
    },
    streamPropertyChanged: (event) => {},
  };
  useEffect(() => {
    if (filteredSubscribers.length === 0 && subscribersToRender.length > 0) {
      setSubscribersToRender((prev) => {
        return prev.map((sub) => {
          return hasRole(sub.stream.connection.data, "speaker")
            ? {
                ...sub,
                isSelectedUser: true,
                isAudioOn: sub.stream.hasAudio,
                isCameraOn: sub.stream.hasVideo,
              }
            : sub;
        });
      });
    }
  }, [filteredSubscribers, subscribersToRender]);

  useEffect(() => {
    if (!selectedGroupSession) return;

    sessionRef?.current?.sessionHelper.session.on("signal", (event) => {
      console.log("SIGNAL", event);

      const [_, eventType] = event.type.split(":");

      switch (eventType) {
        case "blockMicro":
          setSubscribersToRender((prevState) => {
            return prevState.map((sub) =>
              sub.stream.id === event.data
                ? {
                    ...sub,
                    isAudioOn: false,
                  }
                : sub
            );
          });
          break;

        case "unblockMicro":
          setSubscribersToRender((prevState) => {
            return prevState.map((sub) =>
              sub.stream.id === event.data
                ? {
                    ...sub,
                    isAudioOn: true,
                  }
                : sub
            );
          });
          break;

        case "blockCamera":
          setSubscribersToRender((prevState) => {
            return prevState.map((sub) => {
              if (sub.stream.id === event.data) {
                setIsOffSpeaker(isGroupCall);
              }

              return sub.stream.id === event.data
                ? {
                    ...sub,
                    isCameraOn: false,
                  }
                : sub;
            });
          });
          break;

        case "unblockCamera":
          setSubscribersToRender((prevState) => {
            return prevState.map((sub) => {
              if (sub.stream.id === event.data) {
                setIsOffSpeaker(false);
              }

              return sub.stream.id === event.data
                ? {
                    ...sub,
                    isCameraOn: true,
                  }
                : sub;
            });
          });
          break;

        case "handUp":
          setSubscribersToRender((prevState) => {
            return prevState.map((sub) =>
              sub.stream.id === event.data
                ? {
                    ...sub,
                    isHaveQuestion: true,
                  }
                : sub
            );
          });
          setHandUpCount((prev) => {
            if (!prev.includes(event.data)) {
              return [...prev, event.data];
            } else {
              return prev;
            }
          });
          break;

        case "handDown":
          setSubscribersToRender((prevState) => {
            return prevState.map((sub) =>
              sub.stream.id === event.data
                ? {
                    ...sub,
                    isHaveQuestion: false,
                  }
                : sub
            );
          });
          setHandUpCount((prev) => prev.filter((el) => el !== event.data));

          break;

        case "setSelectedUser":
          setSubscribersToRender((prevState) => {
            const activeClientId = prevState.findIndex((el) => el.isSelectedUser);

            if (activeClientId >= 0) {
              sessionRef.current.sessionHelper.session.signal({
                data: prevState[activeClientId].stream.id,
                type: OpentokSignals.RemoveSelectedUser,
              });
            }

            return prevState.map((sub) =>
              sub.stream.id === event.data
                ? {
                    ...sub,
                    isSelectedUser: true,
                    isAudioOn: false,
                    isCameraOn: true,
                  }
                : {
                    ...sub,
                    isAudioOn: false,
                  }
            );
          });
          break;

        case "removeSelectedUser":
          setSubscribersToRender((prevState) => {
            return prevState.map((sub) => {
              return sub.stream.id === event.data
                ? {
                    ...sub,
                    isSelectedUser: false,
                    isAudioOn: false,
                    isCameraOn: true,
                    isHaveQuestion: false,
                  }
                : sub;
            });
          });

          // setHandUpCount(prev => prev.filter(el => el !== event.data));
          break;

        default:
          break;
      }
    });
  }, [selectedGroupSession]);

  return selectedGroupSession.api_key &&
    selectedGroupSession.session_id &&
    selectedGroupSession.token ? (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        // alignItems: "center",
        width: "100%",
        height: "calc(100dvh - 40px)",
        backgroundColor: "#000",
      }}
    >
      <Modal
        open={isOpen}
        onClose={() => {
          setIsOpen(false);
        }}
      >
        <ul
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%,-50%)",
            backgroundColor: "white",
            borderRadius: 8,
            padding: 8,
            display: "grid",
            gap: 12,
            maxHeight: 400,
            overflowY: "auto",
            zIndex: 200000,
          }}
        >
          {subscribersToRender.map((subscriber) => {
            return (
              <li
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  gap: 12,
                }}
                key={subscriber.stream.id}
              >
                <Typography>{subscriber.stream.name}</Typography>
                <div
                  style={{
                    display: "grid",
                    gridTemplateColumns: "1fr 1fr 1fr",
                    alignItems: "center",
                  }}
                >
                  <button
                    onClick={() => handleUserAudioToggle(subscriber)}
                    style={{
                      // backgroundColor: "transparent",
                      border: "none",
                      cursor: "pointer",
                      padding: 0,
                      borderRadius: "50%",
                      marginRight: 12,
                    }}
                  >
                    <img src={subscriber.isAudioOn ? AudioOffIcon : AudioOnIcon} alt="Audio" />
                  </button>

                  <button
                    onClick={() => handleUserVideoToggle(subscriber)}
                    style={{
                      // backgroundColor: "transparent",
                      border: "none",
                      cursor: "pointer",
                      padding: 0,
                      marginRight: 12,
                      borderRadius: "50%",
                    }}
                  >
                    <img src={subscriber.isCameraOn ? CamOnIcon : CamOffIcon} alt="Audio" />
                  </button>

                  {subscriber.isHaveQuestion ? (
                    <button
                      onClick={() => {
                        sessionRef.current.sessionHelper.session.signal({
                          type: OpentokSignals.SetSelectedUser,
                          data: subscriber.stream.streamId,
                        });

                        sessionRef.current.sessionHelper.session.signal({
                          data: subscriber.stream.id,
                          type: OpentokSignals.HandDown,
                        });

                        setIsOpen(false);
                      }}
                      style={{
                        border: "none",
                        cursor: "pointer",
                        position: "relative",
                        padding: 0,
                        borderRadius: "50%",
                        width: 58,
                      }}
                    >
                      <div
                        style={{
                          backgroundColor: "#F44336",
                          width: 20,
                          height: 20,
                          borderRadius: 12,
                          position: "absolute",
                          top: 0,
                          right: -3,
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <p
                          style={{
                            fontSize: 11,
                            color: "#fff",
                          }}
                        >
                          {subscriber.isHaveQuestion ? 1 : 0}
                        </p>
                      </div>
                      <img src={HandUpIcon} alt="Hand" />
                    </button>
                  ) : (
                    <div></div>
                  )}
                </div>
              </li>
            );
          })}
        </ul>
      </Modal>
      {/* <Typography style={{ color: "#fff", padding: "16px", marginBottom: "80px" }}>
        {selectedGroupSession.title}
      </Typography> */}
      <OTSession
        width={"100%"}
        ref={sessionRef}
        eventHandlers={sessionEventHandlers}
        onConnect={() => {
          sessionRef.current?.sessionHelper?.session?.publish(publisher);
        }}
        apiKey={selectedGroupSession.api_key}
        sessionId={selectedGroupSession.session_id}
        token={selectedGroupSession.token}
        onSignalRecieve={(data) => {
          console.log("data recieve", data);
        }}
        // key={selectedGroupSession.token}
      >
        <div
          style={{
            display: "grid",
            // flexDirection: "row",
            // justifyContent: "flex-start",
            height: "calc(100dvh - 112px)",
            width: "100%",
          }}
        >
          {filteredSubscribers.map((subscriber) => {
            // console.log("subscriber", subscriber);

            return (
              <div
                style={{
                  width: "100%",
                  // height: "calc(100vh - 40px)",
                  height: "100%",

                  position: "relative",
                  display: "flex",
                  justifyContent: "center",
                  backgroundColor: "#000",
                }}
                key={subscriber.stream.id}
              >
                <p
                  style={{
                    position: "absolute",
                    top: 8,
                    zIndex: 100000,
                    backgroundColor: "black",
                    padding: 4,
                    borderRadius: 4,
                    color: "white",
                  }}
                >
                  {subscriber.name}
                </p>
                {subscriber.isSelectedUser ? (
                  <OTSubscriber
                    key={subscriber.stream.id}
                    stream={subscriber.stream}
                    session={sessionRef?.current?.sessionHelper?.session}
                    style={{
                      maxWidth: "100%",
                      width: "100%",
                      height: "100%",
                      // height: "calc(100vh - 40px)",
                    }}
                    properties={{
                      width: "100%",
                      height: "100%",
                      showControls: false,
                      fitMode: "contain",
                    }}
                  />
                ) : (
                  <div
                    style={{
                      width: "100%",
                      maxWidth: 600,
                      backgroundColor: "#000",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <img
                      src={NoUserIcon}
                      alt="no user"
                      style={{
                        width: "20%",
                        height: "20%",
                        minHeight: 75,
                        minWidth: 75,
                      }}
                    />
                  </div>
                )}
                <div
                  style={{
                    position: "absolute",
                    bottom: 15,
                    left: 0,
                    zIndex: 100,
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  {!subscriber.isSpeaker && (
                    <button
                      onClick={() => {
                        sessionRef.current.sessionHelper.session.signal({
                          data: subscriber.stream.id,
                          type: OpentokSignals.HandDown,
                        });

                        sessionRef.current.sessionHelper.session.signal({
                          data: subscriber.stream.id,
                          type: OpentokSignals.RemoveSelectedUser,
                        });
                      }}
                      style={{
                        backgroundColor: "transparent",
                        border: "none",
                        cursor: "pointer",
                        margin: "0 10px",
                      }}
                    >
                      <img src={HandDown} />
                    </button>
                  )}
                  <button
                    onClick={() => handleUserAudioToggle(subscriber)}
                    style={{
                      backgroundColor: "transparent",
                      border: "none",
                      cursor: "pointer",
                      margin: "0 10px",
                    }}
                  >
                    <img src={subscriber.isAudioOn ? AudioOffIcon : AudioOnIcon} alt="Audio" />
                  </button>

                  <button
                    onClick={() => handleUserVideoToggle(subscriber)}
                    style={{
                      backgroundColor: "transparent",
                      border: "none",
                      cursor: "pointer",
                      margin: "0 10px",
                    }}
                  >
                    <img src={subscriber.isCameraOn ? CamOnIcon : CamOffIcon} alt="Audio" />
                  </button>
                </div>
              </div>
            );
          })}
        </div>
      </OTSession>
      <div
        style={{
          marginTop: "auto",
          alignSelf: "center",
        }}
      >
        <button
          onClick={() => setIsOpen((prev) => !prev)}
          style={{
            backgroundColor: "transparent",
            border: "none",
            cursor: "pointer",
            margin: "0 10px",
            position: "relative",
          }}
        >
          <div
            style={{
              backgroundColor: "#F44336",
              width: 20,
              height: 20,
              borderRadius: 12,
              position: "absolute",
              top: 0,
              right: -3,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <p
              style={{
                fontSize: 11,
                color: "#fff",
              }}
            >
              {handUpCount.length}
            </p>
          </div>
          <img src={HandUpIcon} alt="Hand" />
        </button>

        <button
          onClick={() => {
            sessionRef.current.sessionHelper.session.signal({
              type: OpentokSignals.EndCall,
            });

            onDisconnect();
          }}
          style={{
            backgroundColor: "transparent",
            border: "none",
            cursor: "pointer",
            margin: "0 10px",
          }}
        >
          <img src={DeclineBtn} alt="Decline" />
        </button>
      </div>
    </div>
  ) : null;
}
