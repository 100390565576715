import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { TranslationToolBar } from "./TranslationToolBar";
import { useActions } from "../../hooks/useActions";
import { FilePreview } from "./FilePreview";
import { DefaultPage } from "./DefaultPage";
import { RefusalForm } from "./RefusalForm";
import { makeStyles, Box } from "@material-ui/core";
import { TranslationFiles } from "./TranslationFiles";
import { TranslationButtons } from "./TranslationButtons";
import {
  disactivateReadyToRecord,
  toggleReadyToRecord,
} from "src/reducers/VideoRecorder/VideoRecorderSlice";
import { AuthService } from "src/services/AuthService";

const useStyles = makeStyles({
  previewBox: {
    // alignItems: "center",
    justifyContent: "space-between",
    gap: "16px",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    padding: "20px 0",
  },
  toast: {
    background: "lime",
    display: "flex",
    flexDirection: "column",
    height: "max-content",
    borderRadius: "4px",
    padding: "5px 25px",
    color: "#fff",
  },
});

export const TranslationPage = () => {
  const classes = useStyles();
  const {
    refuseToTranslate,
    signForTranslation,
    setIsPressed,
    setCurrentTranslations,
    setCompleted,
    selectTranslation,
    // addMessages,
  } = useActions();
  const { all, selectedId, isPressed, refusedToTranslate, currentTranslations } = useSelector(
    (state) => state.translationsReducer
  );

  const translationFilesRef = React.useRef(null);
  const auth = AuthService.getUser();
  // const { isOpen, close, open } = useIsOpen();
  const dispatch = useDispatch();

  // const renderToast = ({ name, duration, startingAt }) => {
  //   const date = applyTranslationFormat(startingAt);

  //   return (
  //     <Paper className={classes.toast} elevation={5}>
  //       <Box>{name}</Box>
  //       <Box>{duration}</Box>
  //       <Box>{date}</Box>
  //     </Paper>
  //   );
  // };

  useEffect(() => {
    if (!translationFilesRef.current) {
      return;
    }
    const element = translationFilesRef.current;
    setTimeout(() => {
      element.scrollTo({
        top: element.scrollHeight,
        behavior: "smooth",
      });
    }, 700);
  }, [translationFilesRef, currentTranslations?.messages]);

  useEffect(() => {
    // console.log('set current ==>',all.find((item) => item.id === selectedId));
    setCurrentTranslations(all.find((item) => item.id === selectedId));
    setIsPressed(false);
    signForTranslation(false);
    refuseToTranslate(false);
    setCompleted(false);
  }, [selectedId]);

  useEffect(() => {
    return () => {
      selectTranslation(null);
      setCurrentTranslations(null);
    };
  }, []);

  // const handleSubmitVideo = async (form, setProgressBar, file) => {
  //   TranslationsService.sendRequestVideo(selectedId, form, setProgressBar);
  //   addMessages({
  //     id: moment().unix(),
  //     type: "video",
  //     content: URL.createObjectURL(file),
  //     thumbnail: null,
  //     created_at: moment().unix(),
  //   });
  // };

  if (!selectedId) {
    return <DefaultPage />;
  }

  if (!currentTranslations) {
    return null;
  }

  return isPressed ? (
    <>
      <TranslationToolBar />
      <FilePreview />
    </>
  ) : (
    <Box className={classes.previewBox}>
      {/* <Box position="absolute"></Box> */}
      <TranslationFiles ref={translationFilesRef} />
      {/* <Modal open={isOpen} onClose={close}>
        <VideoRecorder onSubmit={handleSubmitVideo} close={close} />
      </Modal> */}
      {refusedToTranslate && <RefusalForm />}
      <TranslationButtons
        setIsReadyToRecord={() => {
          dispatch(toggleReadyToRecord());
        }}
        deactivateIsReadyToRecord={() => {
          dispatch(disactivateReadyToRecord());
        }}
      />
    </Box>
  );
};
