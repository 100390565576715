import { createAsyncThunk } from "@reduxjs/toolkit";
import { InterpreterService } from "src/services/InterpreterService";
import { AuthService } from "src/services/AuthService";
import { Operator } from "src/types";

export const fetchCurrentInterpreter = createAsyncThunk(
  "interpreter/fetchCurrentInterpreter",
  async () => {
    try {
      const currentUser = AuthService.getUser();
      const interpreters = (await InterpreterService.getInterpreters()).data;

      const currentInterpreter =
        interpreters.find((interpreter: Operator) => interpreter.id === currentUser.id) || null;

      return { currentInterpreter, allInterpreters: interpreters };
    } catch (error) {
      console.error("Error fetching current interpreter:", error);
      throw error;
    }
  }
);
