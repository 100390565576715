export enum OpentokSignals {
  EndCall = "endCall",
  ChatMessage = "chatMessage",
  BlockMicro = "blockMicro",
  UnblockMicro = "unblockMicro",
  BlockCamera = "blockCamera",
  UnblockCamera = "unblockCamera",
  RemoveSelectedUser = "removeSelectedUser",
  SetSelectedUser = "setSelectedUser",
  HandDown = "handDown",
  PayAttention = "payAttention",
}
